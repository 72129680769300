

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes';
import store from '@/store';
import auth from '../utils/auth';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach(async (to, from, next) => {
  auth.getConf().then((response) => {
    const isMipih = response.CONF !== undefined && response.CONF.MIPIH === true;
    store.dispatch('auth/setMIPIH', isMipih);
  });
  if (to.name === 'login.token' || to.name === 'login.token.withFranceConnect' || to.name === 'login.guest') {
    const session = {
      token: to.params.token,
      guest: to.name === 'login.guest',
    };
    store.dispatch('auth/setToken', session)
      .then((response) => {
        if (to.params.franceConnectToken) {
          store.dispatch('auth/setFranceConnectToken', to.params.franceConnectToken);
        }
        if (session.guest) {
          // eslint-disable-next-line no-console
          console.log('session.guest');
          // eslint-disable-next-line no-console
          console.log(response);
          next({
            name: 'guest.index',
          });
        } else {
          store.dispatch('user/getUserInfo');
          next({
            name: 'home.index',
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.$store.dispatch('auth/setToken', { token: undefined }).then(() => {
          window.location = process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC;
        });
      });
  } else if (to.name === 'logout') {
    localStorage.clear();
    store.dispatch('auth/logout');
  } else if (to.matched.some(m => m.meta.auth) && !store.state.auth.authenticated) {
    /*
     * If the user is not authenticated and visits
     * a page that requires authentication, redirect to the login page
     */
    if (process.env.NODE_ENV === 'development') {
      next({
        name: 'login.index',
      });
    } else {
      window.location = process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC;
    }
  } else if (to.matched.some(m => m.meta.guest) && store.state.auth.authenticated
    && store.state.auth.session.guest === false) {
    /*
     * If the user is authenticated and visits
     * a guest page, redirect to the dashboard page
     */
    next({
      name: 'home.index',
    });
  } else if (!(to.matched.some(m => m.meta.guest)) && store.state.auth.authenticated
    && store.state.auth.session.guest) {
    next({
      name: 'guest.index',
    });
  } else {
    next();
  }
});

Vue.router = router;

export default {
  router,
};
