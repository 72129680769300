<template>
  <ul class="password-rules">
    <li v-for="item in formattedItems" :key="item.key" class="password-rules__item">
      <div class="password-rules__item--icon">
        <font-awesome-icon
          :icon="item.icon"
          :class="item.iconClass"
        />
      <span :class="item.textClass">
        {{ $t(item.text) }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faCheck, faTimes);

export default {
  name: 'PasswordRulesCheckList',
  components: {
    FontAwesomeIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItems() {
      return Object.entries(this.value)
        .filter(([key]) => key !== 'isCompliant')
        .map(([key, { translation, isCompliant }]) => ({
          key,
          text: translation,
          icon: isCompliant ? ['fas', 'check'] : ['fas', 'times'],
          iconClass: ['icon', isCompliant ? 'icon--valid' : 'icon--invalid'],
          textClass: ['edp-rules', { valid: isCompliant }],
        }));
    },
  },
};
</script>

<style scoped>

* {
  color: inherit;
}

.password-rules {
  --valid-color: green;
  --invalid-color: red;
  margin: 1rem 0 0 0.5rem;
  padding: 0;
  list-style-type: none;
}

.password-rules__item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  margin-right: 0.5rem;
}

.icon--valid {
  color: var(--valid-color);
}

.icon--invalid {
  color: var(--invalid-color);
}

.edp-rules {
  transition: color 0.3s;
}

.valid {
  color: var(--valid-color);
  font-weight: bold;
}

.edp-rules:not(.valid) {
  color: var(--invalid-color);
  word-break: break-all;
}
</style>
