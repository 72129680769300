/*
function call to communicate with mobile app
*/
export default {

  postForMobile(message) {
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
      window.webkit.messageHandlers.postMessage(JSON.stringify(message));
    }
  },
};
