const RULE_TYPES = {
  VALIDATION: 'validation',
  CONFIRMATION: 'confirmation',
};

const LENGTH = {
  MIN: 12,
  MAX: 70,
};

const rules = {
  [RULE_TYPES.VALIDATION]: new Map([
    ['hasLowercase', {
      translation: 'updatePassword.rules.validation.lowercase',
      validate: password => /[a-z]/.test(password),
    }],
    ['hasUppercase', {
      translation: 'updatePassword.rules.validation.uppercase',
      validate: password => /[A-Z]/.test(password),
    }],
    ['hasDigit', {
      translation: 'updatePassword.rules.validation.number',
      validate: password => /\d/.test(password),
    }],
    ['isLengthValid', {
      translation: 'updatePassword.rules.validation.length',
      validate: password => password.length >= LENGTH.MIN && password.length <= LENGTH.MAX,
    }],
    ['hasTwoSpecialCharacters', {
      translation: 'updatePassword.rules.validation.specialchar',
      validate: password => /(?:["&~#'{([\-|`_^@)\]=+}$%*!:/;.,?<>\\\\].*){2,}/.test(password),
    }],
  ]),
  [RULE_TYPES.CONFIRMATION]: new Map([
    ['confirmedPassword', {
      translation: 'updatePassword.rules.confirmation.sameAsNew',
      validate: (password, confirm) => password === confirm,
    }],
  ]),
};

const applyRules = (data, ruleType) => Object.fromEntries(
  Array.from(rules[ruleType]).map(([key, rule]) => [
    key,
    {
      ...rule,
      isCompliant: rule.validate(data.password, data.confirm),
    },
  ]),
);

const isCompliant = result => Object.values(result).every(rule => rule.isCompliant);

export const validatePassword = ({ password, confirm }) => {
  const result = applyRules({ password, confirm }, RULE_TYPES.VALIDATION);
  return { ...result, isCompliant: isCompliant(result) };
};

export const confirmPassword = ({ password, confirm }) => {
  const result = applyRules({ password, confirm }, RULE_TYPES.CONFIRMATION);
  return { ...result, isCompliant: isCompliant(result) };
};

export const passwordRules = {
  validation: Object.fromEntries(rules[RULE_TYPES.VALIDATION]),
  confirmation: Object.fromEntries(rules[RULE_TYPES.CONFIRMATION]),
  length: LENGTH,
};
