<template>
  <form @submit.prevent="submitForm">
    <div :class="inputGroupClass" v-if="askForCurrentPassword">
      <label class="el-input-group__label">{{ $t('updatePassword.current.label') }}</label>
      <input
        ref="currentPassword"
        name="currentPassword"
        class="el-input-group__input"
        v-model="password.current"
        :placeholder="$t('updatePassword.current.placeholder')"
        type="password"
        required
      />
    </div>
    <br v-if="askForCurrentPassword" />
    <div :class="[inputGroupClass, 'align-top']">
      <label class="el-input-group__label">{{ $t('updatePassword.new.label') }}</label>
      <input
        ref="newPassword"
        name="newPassword"
        class="el-input-group__input"
        v-model="password.new"
        :placeholder="$t('updatePassword.new.placeholder')"
        @input="checkPassword"
        type="password"
        :maxlength="password.length.MAX"
      />
      <PasswordRulesCheckList
        v-model="password.validation"
      />
    </div>
    <div :class="[inputGroupClass, 'align-top']">
      <label class="el-input-group__label">{{ $t('updatePassword.confirm.label') }}</label>
      <input
        ref="confirmPassword"
        name="confirmPassword"
        class="el-input-group__input"
        :placeholder="$t('updatePassword.confirm.placeholder')"
        required
        v-model="password.confirm"
        @input="checkPassword"
        type="password"
        :maxlength="password.length.MAX"
      />
      <PasswordRulesCheckList
        v-model="password.confirmation"
      />
    </div>
    <div class="ep-button--panel-bottom">
      <button type="submit"
        :class="['el-button', { '-disabled': !isFormValid }]"
        :disabled="!isFormValid">
        {{ submitButtonLabel }}
      </button>
      <button v-if="canCancelUpdate"
        class="el-button el-button--inverted"
        @click="cancelForm"
        type="button">
        {{ cancelButtonLabel }}
      </button>
    </div>
  </form>
</template>

<script>
import {
  passwordRules,
  validatePassword,
  confirmPassword,
} from '@/utils/passwordRules';
import PasswordRulesCheckList from './PasswordRulesCheckList.vue';

export default {
  name: 'UpdatePassword',
  components: {
    PasswordRulesCheckList,
  },
  props: {
    askForCurrentPassword: {
      type: Boolean,
      default: true,
    },
    canCancelUpdate: {
      type: Boolean,
      default: true,
    },
    submitButtonLabel: {
      type: String,
      default: 'Submit',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    smallSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: {
        current: '',
        new: '',
        confirm: '',
        validation: { ...passwordRules.validation },
        confirmation: { ...passwordRules.confirmation },
        length: passwordRules.length,
      },
    };
  },
  computed: {
    isFormValid() {
      const passwordValid = this.password.validation.isCompliant && this.password.confirmation.isCompliant;
      const currentPasswordValid = !this.askForCurrentPassword || this.password.current !== '';
      return passwordValid && currentPasswordValid;
    },
    inputGroupClass() {
      return this.smallSize ? 'ep-input-group--small-size' : 'ep-input-group';
    },
  },
  methods: {
    checkPassword() {
      const { new: newPassword, confirm } = this.password;
      this.password.validation = { ...validatePassword({ password: newPassword, confirm }) };
      this.password.confirmation = { ...confirmPassword({ password: newPassword, confirm }) };
    },
    submitForm() {
      if (this.isFormValid) {
        const formData = {
          newPassword: this.password.new,
          actualPassword: this.password.current,
        };
        this.$emit('submit', formData);
        this.resetForm();
      }
    },
    cancelForm() {
      this.resetForm();
      this.$emit('cancel');
    },
    resetForm() {
      this.password = {
        current: '',
        new: '',
        confirm: '',
        validation: { ...passwordRules.validation },
        confirmation: { ...passwordRules.confirmation },
        length: passwordRules.length,
      };
    },
  },
};
</script>

<style scoped>
.password-rules {
  margin: 1rem 0 0 0.5rem;
  padding: 0;
  list-style-type: none;
}

.password-rules__item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.edp-rules {
  margin-left: 0.5rem;
  transition: color 0.3s;
}

.valid {
  color: green;
  font-weight: bold;
}

.edp-rules:not(.valid) {
  color: red;
}
</style>
