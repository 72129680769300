import Vue from 'vue';

export default {
  getUserInfo() {
    const data = { sessionId: Vue.$store.state.auth.session.token };
    return Vue.$http.post('/edpUser/getInfos', data).then(response => response.data);
  },

  getUsageDatas() {
    const data = { sessionId: Vue.$store.state.auth.session.token };
    return Vue.$http.post('/edpUser/getUsageDatas', data).then(response => response.data);
  },

  getPaySlipAgreementStatus() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/getPaySlipAgreementStatus', data).then(response => response.data);
  },

  enablePaySlipDemat(signatureCode) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      signatureCode,
    };
    return Vue.$http.post('/edpUser/updatePaySLipDemat', data).then(response => response.data);
  },

  disablePaySlipDemat(signatureCode) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      signatureCode,
    };
    return Vue.$http.post('/edpUser/updatePaySLipDemat', data).then(response => response.data);
  },
  // mettre à jour les données de l'utilisateur

  update({
    firstname,
    lastname,
    email,
    address,
    zipCode,
    town,
    phone,
  }) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      firstname,
      lastname,
      email,
      address,
      zipCode,
      town,
      phone,
    };
    return Vue.$http.post('/edpUser/update', data).then(response => response.data);
  },
  // recuperer les document d'identités de l'utilisateur
  getIdentityDocs() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/getIdentityDocs', data).then(response => response.data);
  },

  sendEmailValidationCode(params) {
    return Vue.$http.post('/edpUser/sendEmailValidationCode', params).then(response => response.data);
  },

  verifyEmailValidationCode(params) {
    return Vue.$http.post('/edpUser/verifyEmailValidationCode', params).then(response => response.data);
  },

  // mettre à jour le mot de passe utilisateur
  updatePassword(newPassword, actualPassword) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      newPassword,
      actualPassword,
    };
    return Vue.$http.post('/edpUser/updatePassword', data).then(response => response.data);
  },
  // Liste des trousseaux :
  findServices() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpAccessMemories/search', data).then(response => response.data);
  },
  // Modification d'un trousseau :
  updateService(id, siteName, login, password) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      id,
      siteName,
      login,
      password,
    };
    return Vue.$http.post('/edpAccessMemories/update', data).then(response => response.data);
  },
  // Suppression d'un trousseau :
  deleteService(id) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      id,
    };
    return Vue.$http.post('/edpAccessMemories/delete', data).then(response => response.data);
  },
  // Création d'un trousseau
  addService(siteName, login, password) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,

      siteName,
      login,
      password,
    };
    return Vue.$http.post('/edpAccessMemories/create', data).then(response => response.data);
  },
  retrieveSignatureCode(phone) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      phone,
    };
    // console.log('hello2'); /index.php?api=User&a=retrieveSignatureCode
    return Vue.$http.post('/edpUser/retrieveSignatureCode', data).then(response => response.data);
  },
  // Exporter le contenu du coffre (format zip)
  exportSafebox() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    const headers = {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
      timeout: process.env.VUE_APP_ARCHIVE_TIMEOUT,
    };
    return Vue.$http.post('/edpUser/exportSafeBox', data, headers).then(response => response.data);
  },

  loadFCAuthSub(fcDatas) {
    const data = {
      ...fcDatas,
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/loadFCAuthSub', data).then(response => response.data);
  },

  disableFranceConnectLink() {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
    };
    return Vue.$http.post('/edpUser/disableFranceConnectAuthentication', data).then(response => response.data);
  },

  updateEmail(email, password) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      email,
      password,
    };
    return Vue.$http.post('/edpUser/updateEmail', data).then(response => response.data);
  },
  updateCGU(agreedToLastCGU, isAnAdult) {
    const data = {
      sessionId: Vue.$store.state.auth.session.token,
      agreedToLastCGU,
      isAnAdult,
    };
    return Vue.$http.post('/edpUser/saveCGUAgreement', data).then(response => response.data);
  },
};
