import 'babel-polyfill';
import Vue from 'vue';
import UUID from 'vue-uuid';
import EdocUi from 'edoc-ui';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import zendesk from '@/plugins/zendesk';
import './plugins/axios';
import { router } from './plugins/vue-router';
import './plugins/vuex';
import './plugins/vuex-router-sync';
import i18n from './plugins/vue-i18n';
import loadUserCss from './assets/js/loadUserCss';

import 'edoc-lib';

import App from './App.vue';
import store from './store';

library.add(faCheck, faTimes);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(UUID);
Vue.use(EdocUi);

require('edoc-ui/lib/edoc-ui.css');
require('@/assets/scss/main.scss');

Vue.config.productionTip = false;

store.dispatch('auth/check').then((session) => {
  if (!session.guest) {
    Vue.$store.dispatch('user/getUserInfo').then((response) => {
      zendesk(response.content.email, response.content.lastname);
    });
  }
}).catch(() => {});

loadUserCss();
i18n.locale = navigator.language.substring(0, 2);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
});
