<template>
  <div>
    <resetPwd v-if="shouldDisplayResetPwd" @close="handleResetPwdClose"/>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import trackingServices from '@/plugins/posthog';
import resetPwd from './components/resetPwd.vue';

export default {
  name: 'edoc-perso',
  components: {
    resetPwd,
  },
  data() {
    return {
      shouldResetPassword: false,
    };
  },
  computed: {
    ...mapState({
      isPwdCompliant: state => state.user.isPwdCompliant,
      isUserConnected: state => state.user !== undefined,
    }),
    shouldDisplayResetPwd() {
      return this.isUserConnected && (this.isPwdCompliant === false || this.shouldResetPassword === true);
    },
  },
  methods: {
    handleResetPwdClose() {
      this.shouldResetPassword = false;
      localStorage.removeItem('shouldResetPwd');
    },
  },
  mounted() {
    trackingServices.getPostHogInstance();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('resetPwd') === 'true') {
      localStorage.setItem('shouldResetPwd', 'true');
    }
    this.shouldResetPassword = (localStorage.getItem('shouldResetPwd') === 'true');
  },
  watch: {
    isUserConnected(newValue) {
      this.shouldResetPassword = newValue && localStorage.getItem('shouldResetPwd') === 'true';
    },
  },
};
</script>
