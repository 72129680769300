<template>
  <div class="ep-popin ep-resetPwd">
    <div class="ep-popin__resetPwd">
      <div class="ep-popin__resetPwd__title">{{ $t('reset.title') }}</div>
      <div class="ep-popin__resetPwd__wording">{{ $t('reset.wording') }}</div>
      <UpdatePassword
        @submit="handleSubmitUpdatePassword"
        @cancel="handleCancelUpdatePassword"
        :submitButtonLabel="$t('profile.save')"
        :cancelButtonLabel="$t('profile.logout')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mobile from '@/utils/mobile';
import UpdatePassword from '@/components/password/UpdatePassword.vue';

export default {
  name: 'resetPwd',
  components: {
    UpdatePassword,
  },
  data() {
    return {
      password: {
        actual: '',
        newPassword: '',
        similar: '',
      },
    };
  },
  computed: {
  },
  methods: {
    ...mapActions({
      updatePassword: 'user/updatePassword',
    }),
    handleSubmitUpdatePassword({ newPassword, actualPassword }) {
      this.updatePassword({ newp: newPassword, actual: actualPassword })
        .then((result) => {
          if (result && result.code === 0) {
            mobile.postForMobile({ status: 'success', data: 'password-updated' });
            this.$emit('close');
          }
        });
    },
    handleCancelUpdatePassword() {
      mobile.postForMobile({ status: 'cancelled', data: 'cancel-reset-password' });
      this.$router.push({ name: 'logout' });
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
}
</style>
